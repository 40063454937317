import { CustomerList } from './../models/customer-list';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  catchError,
  forkJoin,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { CustomerEndpoint } from '../endpoints/customer.endpoint';
import {
  FormCustomer,
  IDynamicRequest,
  IRange,
} from '../interface/customer.interface';
import { Paginator } from 'projects/ui-coface/src/app/commons/model/paginator.model';
import { FilterModel } from 'projects/ui-coface/src/app/commons/model/filter.model';
import { ICustomerFilter } from '../interface/customer.filter';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { SessionService } from 'projects/ui-coface/src/app/commons/services/session.service';
import { Parameter } from 'projects/ui-coface/src/app/commons/model/parameter.model';

import { ModalService } from 'projects/ui-coface/src/app/commons/services/dialog.service';
import { HomologacionOperation } from '../../../homologacion/commons/homologacion-operation';
import { IAccountManager } from 'projects/ui-coface/src/app/commons/interface/menu.interface';
import { RoleType } from 'projects/ui-coface/src/app/commons/enums/role.enum';
import { LocalStoreService } from 'projects/ui-coface/src/app/commons/services/local-store.service';
import { StoreName } from 'projects/ui-coface/src/app/commons/enums/store-name.enum';
import { CreateUserStaffEndpoint } from '../../../user-management/commons/endpoints/create-user.endpoint';
import { SuccessfulDynamicComponent } from 'projects/ui-coface/src/app/shared/components/dialogs/successful-dynamic/successful-dynamic.component';

@Injectable({ providedIn: 'root' })
export class CustomerOperation {
  // public dataCustomer;
  public tempManager: IAccountManager[] = [];
  private identifiersForUpdate: { idCompany: number; idScore: number };
  constructor(
    private readonly customerEndpoint: CustomerEndpoint,
    private readonly userEndpoint: CreateUserStaffEndpoint,
    private readonly homologationOpe: HomologacionOperation,
    private readonly session: SessionService,
    private readonly dialog: ModalService,
    private readonly store: LocalStoreService
  ) {}

  public newRange$(body: FormCustomer.IRangeControl[]) {
    const buildBody = this.formatRange(body);
    return this.customerEndpoint.newRange$(buildBody).pipe();
  }

  public createCustomerFinal$(body: FormCustomer.ICustomer) {
    return this.newRange$(body.range!).pipe(
      switchMap((res) => {
        return this.customerEndpoint.newCompany$({
          address: body.address,
          basicFormId: body.quizId,
          commercialName: body.tradeName,
          countryId: +body.countryCode,
          legalName: body.denomination,
          nationalId: body.legalCode,
          reportDuration: body.expirationTerm,
          scorePolicyId: res.id!,
        });
      })
    );
  }

  public rangeSend(body) {
    return this.formatRange(body);
  }
  public newCustomer(body: FormCustomer.ICustomer, quizletters: Array<any>) {
    const officeId = this.tempManager.find((x) => x.id === +body.analystId)
      ?.office?.id;
    const buildBody = this.formNewCustomer(body, officeId);

    return this.customerEndpoint.newCustomer$(buildBody).pipe(
      catchError((error) => {
        if (error.status === 409) {
          this.dialog.open(
            {
              title:
                'No es posible crear al cliente porque ya existe el correo en el sistema',
            },
            SuccessfulDynamicComponent,
            {
              size: 'SMALL',
              disableClose: false,
              closable: 'NO_CLOSE_BY_OUTSIDE',
            }
          );
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      }),
      switchMap((res) => {
        this.store.set(StoreName.CUSTOMER_ID, res.id);
        const observables$: Array<Observable<Object>> = [];
        quizletters.forEach((elm) =>
          observables$.push(
            this.newAttachmentFileCoverLetter(
              elm.quiz,
              {
                folderKey: 'CUSTOM_FORMS',
                languageId: elm.languageFile,
              },
              res.id
            )
          )
        );

        observables$.push(
          this.newAttachmentFileCoverLetter(
            body.coverLetter,
            {
              folderKey: 'PRESENTATION_LETTERS',
              languageId: '',
            },
            res.id
          )
        );

        return forkJoin([
          ...observables$,
          // this.getManagerForACustomer(res.id, body.analystId),
        ]);
      })
    );
  }

  public getAccountManagers$() {
    return this.customerEndpoint
      .getAccountManagers(
        this.session.user.role?.keyword !== RoleType.SUPERUSER
          ? this.session.user.office?.id
          : null
      )
      .pipe(
        map((res) => {
          let arrayParameter = new Array<Parameter>();
          this.tempManager = res.data;
          res.data.forEach((elm) => {
            const parameter = new Parameter(
              elm.id.toString(),
              elm.firstname.toString()
            );
            arrayParameter.push(parameter);
          });
          return arrayParameter;
        })
      );
  }

  public updateCustomers$(
    body: FormCustomer.ICustomer,
    configData: CustomerList
  ) {
    // console.log(body.idUser, body.analystId);
    const { idCompany, idUser, idScore } = body;
    this.identifiersForUpdate = {
      idCompany: idCompany!,
      idScore: idScore!,
    };
    const buildBody = {
      ...this.formUpdateCustomer(body, configData.Office.id),
      // isStaff: true,
      // status: 'ACTIVE',
      // role: { id: 5 },
    } as FormCustomer.IRequestCustomer;
    return this.customerEndpoint.updateCustomer$(buildBody, idUser).pipe();
  }

  public getCustomers$(filter: FilterModel<ICustomerFilter>) {
    // if (this.session.user.role?.keyword !== 'CUSTOMER') {
    //   return this.getInfoBySessionUser().pipe(
    //     switchMap((data) => {
    //       const bodyRQ = this.builderRequest(filter, data.data.office.id);
    //       return this.customerEndpoint.getCustomer$(bodyRQ).pipe(
    //         map((res) => {
    //           const content = res.data['content'];
    //           let paginator = Paginator.backBuild<CustomerList>(res.data);
    //           if (content.length) {
    //             const row = content.map((val) => CustomerList.backBuild(val));
    //             paginator.Content = row;
    //           }
    //           return paginator;
    //         })
    //       );
    //     })
    //   );
    // } else {
    const bodyRQ = this.builderRequest(filter, null);
    return this.customerEndpoint.getCustomer$(bodyRQ).pipe(
      map((res) => {
        const content = res.data['content'];
        let paginator = Paginator.backBuild<CustomerList>(res.data);
        if (content.length) {
          const row = content.map((val) => CustomerList.backBuild(val));
          paginator.Content = row;
        }
        return paginator;
      })
    );
    // }
  }

  public deleteCustomers$(id: number, customerEmail: string) {
    const textError = 'No es posible eliminar el cliente';
    return this.userEndpoint.deleteUser$(id).pipe(
      catchError((error) => {
        this.dialog.open(
          {
            title: textError,
            description: `No se puede eliminar el cliente ${customerEmail}, debido a que tiene proveedores activos`,
          },
          SuccessfulDynamicComponent,
          {
            size: 'SMALL',
            disableClose: false,
            closable: 'NO_CLOSE_BY_OUTSIDE',
          }
        );
        return throwError(() => error);
      })
    );
  }
  public attachmentFileCustomer$(
    files,
    customerId,
    folder: 'CUSTOM_FORMS' | 'PRESENTATION_LETTERS'
  ) {
    const formData = new FormData();
    formData.append('documents', files);
    formData.append('folder', folder);

    return this.customerEndpoint.attachmentFileCustomer$(formData, customerId);
  }

  // public getManagerForACustomer(idCustomer, idAccount) {
  //   return this.customerEndpoint
  //     .getManagerForACustomer$(idCustomer, idAccount)
  //     .pipe();
  // }

  public updateManager(idCustomer, idAccount) {
    return this.customerEndpoint.updateManager$(idCustomer, idAccount).pipe();
  }

  private formNewCustomer(form: FormCustomer.ICustomer, officeId?) {
    let obj: FormCustomer.IRequestCustomer = {
      company: {
        id: this.identifiersForUpdate?.idCompany ?? null,
        // agentEmail: form.email,
        address: form.address,
        commercialName: form.tradeName,
        country: {
          id: +form.countryCode,
        },
        legalName: form.denomination,
        nationalId: form.legalCode,
        basicFormId: +form.quizId,
        scorePolicy: this.formatRangeV2(form.range!),
        reportDuration: +form.expirationTerm,
      },
      reportDuration: +form.expirationTerm,
      firstname: form.firstname,
      lastname: form.lastname,
      // firstname: form.contactName, //change
      // lastname: '', //change
      reportSalePrice: form.reportSalePrice,
      reportCurrency: {
        id: Number(form.reportCurrency),
      },
      office: {
        id: officeId,
      },
      language: {
        id: Number(form.language),
      },
      email: form.email,
      phoneNumber: form.phoneNumber,
      // managerId: form.analystId,
      manager: {
        id: Number(form.analystId),
      },
      // reportSalePrice: form.reportSalePrice!,
    };
    delete obj.company.scorePolicy.id;
    return obj;
  }

  private formUpdateCustomer(form: FormCustomer.ICustomer, officeId?) {
    let obj: FormCustomer.IRequestCustomer = {
      company: {
        id: this.identifiersForUpdate?.idCompany ?? null,
        // agentEmail: form.email,
        address: form.address,
        commercialName: form.tradeName,
        countryId: +form.countryCode,
        legalName: form.denomination,
        nationalId: form.legalCode,
        scorePolicy: this.formatRangeV2(form.range!),
      },
      reportDuration: +form.expirationTerm,
      firstname: form.firstname,
      lastname: form.lastname,
      // firstname: form.contactName, //change
      // lastname: '', //change
      reportSalePrice: form.reportSalePrice,
      officeId: officeId,
      languageId: Number(form.language),
      email: form.email,
      phoneNumber: form.phoneNumber,
      // managerId: form.analystId,
      managerId: Number(form.analystId),
      // reportSalePrice: form.reportSalePrice!,
    };
    delete obj.company.scorePolicy.id;
    return obj;
  }

  private formatRange(array: FormCustomer.IRangeControl[]): IRange {
    let obj = {} as IRange;

    array.forEach((elm) => {
      switch (elm.classification) {
        case 'A':
          obj['fromA'] = +elm.from;
          obj['toA'] = +elm.until;
          break;
        case 'B':
          obj['fromB'] = +elm.from;
          obj['toB'] = +elm.until;
          break;
        case 'C':
          obj['fromC'] = +elm.from;
          obj['toC'] = +elm.until;
          break;
        case 'D':
          obj['fromD'] = +elm.from;
          obj['toD'] = +elm.until;
          break;

        default:
          break;
      }
    });
    return obj;
  }

  private formatRangeV2(
    array: FormCustomer.IRangeControl[]
  ): FormCustomer.IScorePolicy {
    let obj = {} as FormCustomer.IScorePolicy;
    obj['id'] = this.identifiersForUpdate?.idScore ?? null;
    array.forEach((elm) => {
      switch (elm.classification) {
        case 'A':
          obj['minRangeA'] = +elm.from;
          obj['maxRangeA'] = +elm.until;
          break;
        case 'B':
          obj['minRangeB'] = +elm.from;
          obj['maxRangeB'] = +elm.until;
          break;
        case 'C':
          obj['minRangeC'] = +elm.from;
          obj['maxRangeC'] = +elm.until;
          break;
        case 'D':
          obj['minRangeD'] = +elm.from;
          obj['maxRangeD'] = +elm.until;
          break;

        default:
          break;
      }
    });
    return obj;
  }

  public builderRequest(filter: FilterModel<ICustomerFilter>, officeId) {
    const req = new GenericRequestConfig();
    const buildHeader = {
      nationalId: filter.FilterValue?.nationalId,
      legalName: filter.FilterValue?.legalName,
      countryId: filter.FilterValue?.officeId,
      commercialName: filter.FilterValue?.commercialName,
    };

    req.params = new HttpParams();
    if (officeId) {
      req.params = req.params.set('officeId', officeId);
    }
    req.url = `${environment.urlBaseEndpointV2}/backoffice/customers`;
    req.params = req.params
      .set('page', filter.Page - 1)
      .set('size', filter.Size);
    for (const [key, value] of Object.entries(buildHeader)) {
      if (value) {
        req.params = req.params.set(key, value);
      }
    }
    return req;
  }

  //GENERATE RAMDON UUID
  public generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public newAttachmentFileCoverLetter(
    file: File,
    bodyValue: {
      languageId: number | string;
      folderKey: string;
    },
    userId?
  ) {
    const formData = new FormData();
    // const blob = new Blob([JSON.stringify(bodyValue)], {
    //   type: 'application/json',
    // });
    formData.append('languageId', bodyValue.languageId + '');
    formData.append('document', file);
    formData.append('folderKey', bodyValue.folderKey);
    if (bodyValue.languageId == '') formData.delete('languageId');
    return this.customerEndpoint.newAttachmentFileLetter$(formData, userId);
  }
}
