import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseCoface } from 'projects/ui-coface/src/app/commons/interface/response-coface.interface';
import { Paginator } from 'projects/ui-coface/src/app/commons/model/paginator.model';
import { GenericRequestConfig } from 'projects/ui-coface/src/app/commons/model/request.config';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { ICreateUserStaff } from '../interface/create-user.interface';
import { IListUser } from '../interface/list-user.interface';

@Injectable({ providedIn: 'root' })
export class CreateUserStaffEndpoint {
  public constructor(private http: HttpClient) {}

  public createUserStaff$(body: ICreateUserStaff) {
    const url = `${environment.urlBaseEndpointV2}/backoffice/coworkers`;
    const headers = new HttpHeaders().set('no-intercept', 'true');
    return this.http.post<ICreateUserStaff>(url, body, { headers });
  }

  public getUsers$(filters: GenericRequestConfig) {
    const url = `${environment.urlBaseEndpointV2}/backoffice/coworkers`;
    return this.http.get<Paginator<IListUser>>(url, filters);
  }

  public deleteUser$(id: number) {
    const url = `${environment.urlBaseEndpointV2}/delete-user/${id}`;
    const headers = new HttpHeaders({ 'no-intercept': 'true' });
    return this.http.patch(
      url,
      {},
      {
        headers: headers,
      }
    );
  }
}
