import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/ui-coface/src/environments/environment';
import { Observable } from 'rxjs';
import { IResponseCoface } from '../interface/response-coface.interface';
import {
  IResultFile,
  IUploadFile,
  IUploadFileRecalification,
  IUploadMassive,
} from '../interface/upload.interface';
import { Paginator } from '../model/paginator.model';
import { GenericRequestConfig } from '../model/request.config';

@Injectable()
export class UploadHttp {
  public uplaod = '/file';
  public constructor(private http: HttpClient) {}

  public upload$(formData: FormData): Observable<IUploadFile> {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const url = `${environment.urlBaseEndpoint}/files/upload`;
    return this.http.post<IUploadFile>(url, formData, { headers });
  }

  // upload file to folder
  public uploadAttachmentToPlatform(
    formData: FormData,
    folderData
  ): Observable<IUploadFile> {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const url = `${environment.urlBaseEndpointV2}/attachments/upload`;
    const params = new HttpParams().set('folder', folderData);
    return this.http.post<IUploadFile>(url, formData, {
      params: params,
      headers: headers,
    });
  }

  // // upload file to folder
  // public uploadMultipleAttachmentToPlatform(
  //   formData: FormData,
  //   folderData
  // ): Observable<IUploadFile> {
  //   const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
  //   const url = `${environment.urlBaseEndpoint}}/attachments/batch-upload`;
  //   const params = new HttpParams().set('folder', folderData);
  //   return this.http.post<IUploadFile>(url, formData, {
  //     params: params,
  //     headers: headers,
  //   });
  // }

  //endpoint recalificacion
  public uploadAttachmentsRecalification(
    formData: FormData,
    folderData,
    idHomologation
  ): Observable<IResponseCoface<IUploadFileRecalification[]>> {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const url = `${environment.urlBaseEndpoint}/homologation/recalification-files/`;
    const params = new HttpParams()
      .set('folder', folderData)
      .set('homologation', idHomologation);
    return this.http.post<IResponseCoface<IUploadFileRecalification[]>>(
      url,
      formData,
      { params: params, headers: headers }
    );
  }

  //endpoint homologacion
  public uploadAttachmentsHomologation(
    formData: FormData,
    folderData,
    idHomologation
  ): Observable<IResponseCoface<IUploadFileRecalification[]>> {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const url = `${environment.urlBaseEndpoint}/homologation/homologations-files/`;
    const params = new HttpParams()
      .set('folder', folderData)
      .set('homologation', idHomologation);
    return this.http.post<IResponseCoface<IUploadFileRecalification[]>>(
      url,
      formData,
      { params: params, headers: headers }
    );
  }

  // public getAttachmentsByFolder$(folder) {
  //   const url = `${environment.urlBaseEndpoint}/attachments`;
  //   const params = new HttpParams().set('folder', folder);
  //   return this.http.get<IResponseCoface<Paginator<IUploadFile>>>(url, { params: params });
  // }

  // carga masiva
  //bulk upload endpoint
  public uploadMassive$(
    formData: FormData
  ): Observable<IResponseCoface<IUploadMassive>> {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const url = `${environment.urlBaseEndpoint}/backoffice/tools/bulk-imports/suppliers`;
    return this.http.post<IResponseCoface<IUploadMassive>>(url, formData, {
      headers,
    });
  }

  public uploadMassiveClient$(
    formData: FormData
  ): Observable<IResponseCoface<IUploadMassive>> {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const url = `${environment.urlBaseEndpoint}/tools/bulk-imports/suppliers`;
    return this.http.post<IResponseCoface<IUploadMassive>>(url, formData, {
      headers,
    });
  }

  // bulk upload results endpoint
  public uploadMassiveResult$(idUpload) {
    const url = `${environment.urlBaseEndpoint}/bulk/results`;
    let params = new HttpParams().set('bulk-id', idUpload);
    return this.http.get<IResultFile>(url, { params: params });
  }

  // history
  // summary upload massive
  public toShowSummaryMassive$(filters: GenericRequestConfig) {
    const url = `${environment.urlBaseEndpoint}/bulk-load-files`;
    return this.http.get<IResponseCoface<Paginator<IResultFile>>>(url, filters);
  }

  // download observed
  public downloadFileObserved$(idBulkUpload) {
    const params = new HttpParams().set('bulkId', idBulkUpload);
    const headers = new HttpHeaders({ Accept: 'application/octet-stream' });
    const url = `${environment.urlBaseEndpoint}/tools/bulk-imports/suppliers/invalid-records`;
    return this.http.get(url, {
      params: params,
      headers: headers,
      responseType: 'blob',
    });
  }

  //  NEW

  public uploadAttachment$(formdata: FormData, formId: string) {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const url = `${environment.urlBaseEndpoint}/attachments/upload/${formId}`;

    return this.http.post<IUploadFileRecalification>(url, formdata, {
      headers: headers,
    });
  }
}
