<div class="py-7 flex flex-col gap-5">
  <h2 class="text-xl text-center font-semibold text-coface-primary">
    Eliminación de Proveedor
  </h2>
  <p class="text-gray-500 text-center">
    Estás seguro de eliminar al Cliente {{ supplierData?.legalNameSupplier }} ?
  </p>
  <div class="flex flex-col gap-4 justify-center w-[200px] mx-auto">
    <coface-button
      text="Eliminar"
      (clicked)="onDelete()"
      [loading]="loading"
      [disabled]="loading"
    ></coface-button>
    <coface-button
      [text]="'Cancelar'"
      [disabled]="loading"
      (clicked)="onCancel()"
      color="tertiary"
    ></coface-button>
  </div>
</div>
