<section class="flex flex-col justify-center gap-5 p-5 relative">
  <h3 class="text-coface-primary text-2xl text-center font-bold tracking-wide">
    Asignación de Analista
  </h3>
  <button
    class="rounded-full text-center bg-gray-300 text-white w-6 h-6 text-xs flex justify-center items-center p-1 absolute top-1 -right-4 cursor-pointer hover:bg-gray-400"
    (click)="closeModal()"
  >
    <span> &#x2715; </span>
  </button>
  <div class="px-10">
    <ul class="text-gray-500 list-none mb-5 space-y-2">
      <li class="flex justify-between">
        <span>Operación:</span>
        <p class="font-semibold">N° {{ dataOperation?.homologationId }}</p>
      </li>
      <li class="flex justify-between">
        <span>Proveedor:</span>
        <p class="font-semibold">{{ dataOperation?.supplierName }}</p>
      </li>
      <li class="flex justify-between">
        <span>Cliente:</span>
        <p class="font-semibold">{{ dataOperation?.customerName }}</p>
      </li>
    </ul>
    <coface-select
      cofaceReactiveForm
      [items]="dataAnalyst"
      [formControl]="selectedAnalyst"
    >
    </coface-select>
  </div>
  <div class="w-[200px] mx-auto mt-20">
    <coface-button
      text="Guardar"
      class="mt-4"
      [loading]="loading"
      [disabled]="selectedAnalyst.invalid || loading"
      (clicked)="onAssignAnalyst()"
    ></coface-button>
  </div>
</section>
