import { Injectable } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { CofacePresenter } from '../../../../commons/utils/coface.presenter';
import { FormCustomer } from '../../commons/interface/customer.interface';
import { LETTER_CLASSIFICATION } from '../../commons/utils/customer.enums';
import { valueQuizSelected } from '../../commons/validators/quizletter.validator';
import { repeatedDocAsyncValidator } from '../../commons/validators/range.validators';

@Injectable({ providedIn: 'root' })
export class SingleUploadPresenter extends CofacePresenter<
  Partial<FormCustomer.ICustomer>
> {
  public legalCode: UntypedFormControl;
  public denomination: UntypedFormControl;
  public address: UntypedFormControl;
  public phoneNumber: UntypedFormControl;
  public email: UntypedFormControl;
  // public contactName: FormControl;
  public firstname: UntypedFormControl;
  public lastname: UntypedFormControl;
  public countryCode: UntypedFormControl;
  public coverLetter: UntypedFormControl;
  public quizId: UntypedFormControl;
  public quizLetter: UntypedFormControl;
  public expirationTerm: UntypedFormControl;
  public analystId: UntypedFormControl;
  public reportSalePrice: UntypedFormControl;
  public reportCurrency: UntypedFormControl;
  public qualifRangesId: UntypedFormControl;
  public range: UntypedFormArray;
  public tradeName: UntypedFormControl;
  public idUser?: UntypedFormControl;
  public idScore?: UntypedFormControl;
  public idCompany?: UntypedFormControl;

  /**file */
  public quiz: UntypedFormControl;
  public languageFile: UntypedFormControl;
  public quizletters: UntypedFormArray;
  public language: UntypedFormControl;
  // public agentEmail: FormControl;

  public constructor(private fb: UntypedFormBuilder) {
    super();
    this.createValidators();
    this.initForm();
  }

  private initForm() {
    this.form = this.fb.group({
      legalCode: this.legalCode,
      denomination: this.denomination,
      address: this.address,
      phoneNumber: this.phoneNumber,
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      // contactName: this.contactName,
      countryCode: this.countryCode,
      coverLetter: this.coverLetter,
      // quizId: this.quizId,
      quizLetter: this.quizLetter,
      tradeName: this.tradeName,
      expirationTerm: this.expirationTerm,
      analystId: this.analystId,
      reportSalePrice: this.reportSalePrice,
      reportCurrency: this.reportCurrency,
      qualifRangesId: this.qualifRangesId,
      idUser: this.idUser,
      idCompany: this.idCompany,
      idScore: this.idScore,
      // agentEmail: this.agentEmail,
      range: this.range,
      quiz: this.quiz,
      languageFile: this.languageFile,
      quizletters: this.quizletters,

      language: this.language,
    });
  }

  private createValidators() {
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/;
    this.legalCode = new UntypedFormControl('', [Validators.required]);
    this.denomination = new UntypedFormControl('', [Validators.required]);
    this.address = new UntypedFormControl('', [Validators.required]);
    this.phoneNumber = new UntypedFormControl('', [Validators.required]);
    this.email = new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(emailPattern),
    ]);
    // this.contactName = new FormControl('', [Validators.required]);
    this.firstname = new UntypedFormControl('', [Validators.required]);
    this.lastname = new UntypedFormControl('', [Validators.required]);
    this.countryCode = new UntypedFormControl('', [Validators.required]);
    this.coverLetter = new UntypedFormControl('', [Validators.required]);
    // this.quizId = new FormControl('', [Validators.required]);
    this.quizLetter = new UntypedFormControl('');
    this.expirationTerm = new UntypedFormControl('', [Validators.required]);
    this.analystId = new UntypedFormControl('', [Validators.required]);
    this.tradeName = new UntypedFormControl('', [Validators.required]);

    this.language = new UntypedFormControl('', [Validators.required]);

    this.reportSalePrice = new UntypedFormControl('');
    this.reportCurrency = new UntypedFormControl('');
    this.qualifRangesId = new UntypedFormControl('');
    this.idUser = new UntypedFormControl();
    this.idScore = new UntypedFormControl();
    this.idCompany = new UntypedFormControl();
    this.languageFile = new UntypedFormControl();
    this.quiz = new UntypedFormControl();
    this.quizletters = new UntypedFormArray([]);
    // this.agentEmail = new FormControl('', [Validators.required, Validators.email]);

    this.range = new UntypedFormArray(
      [
        this.fb.group(
          { from: '', until: '', classification: LETTER_CLASSIFICATION.A },
          { updateOn: 'change' }
        ),
        this.fb.group(
          { from: '', until: '', classification: LETTER_CLASSIFICATION.B },
          { updateOn: 'change' }
        ),
        this.fb.group(
          { from: '', until: '', classification: LETTER_CLASSIFICATION.C },
          { updateOn: 'change' }
        ),
        this.fb.group(
          { from: '', until: '', classification: LETTER_CLASSIFICATION.D },
          { updateOn: 'change' }
        ),
      ],
      { asyncValidators: repeatedDocAsyncValidator() }
    );
  }

  public addQuizLetter(paramLanguage = null, paramQuiz: string | File = '') {
    const newQuizLetter = this.fb.group({
      languageFile: [paramLanguage, Validators.required],
      quiz: [paramQuiz, Validators.required],
      flag: [false],
    });
    this.getQuizLetters().push(newQuizLetter);
  }

  // addPatchValue(element) {
  //   let asd = this.Form.controls['quizletters'] as FormArray;

  addRangeControl(element) {
    return this.fb.group({
      from: element.minRange,
      until: element.maxRange,
      classification: element.classification,
    });
  }
  deleteQuizLetter(quizLetterIndex: number) {
    this.getQuizLetters().removeAt(quizLetterIndex);
  }

  getQuizLetters() {
    return this.Form.controls['quizletters'] as UntypedFormArray;
  }

  public fileEvent(e: Event, quizLetter) {
    quizLetter.get('flag')?.setValue(true);
  }

  public changeFileEvent(quizLetter) {
    quizLetter.get('flag')?.setValue(false);
    quizLetter.get('quiz')?.reset('');
  }

  resetForm() {
    Object.keys(this.form.controls).forEach((key) => {
      if (key === 'range') {
        let formGroupRange = this.form.get(key) as UntypedFormArray;
        formGroupRange.controls.forEach((group) => {
          group.get('from')?.reset();
          group.get('until')?.reset();
        });
      } else {
        this.form.get(key)?.reset();
      }
    });
  }

  hasErrorEmailControl() {
    return (
      this.form.get('email')?.invalid &&
      this.form.get('email')?.touched &&
      (this.form.get('email')?.hasError('required') ||
        this.form.get('email')?.hasError('pattern'))
    );
  }
}
